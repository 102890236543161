@import "../admin/navbar-dropdowns.css";
@import "../admin/sidebar-default.css";

body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
a,
td {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    width: 100%;
    height: 100%;
    background: #f4f6fa;
    font-family: 'Open Sans', Times, serif;
    font-size: 1rem;
    color: #444;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    overflow-x: hidden
}

#body {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    transition: all 0.3s;
}

/*------------------------------------------------------------------
[2. Header / #header]
*/
#body>.navbar {
    padding: 0 1.5rem;
    min-height: 54px;
    box-shadow: none;
    border-bottom: 1px solid rgba(101, 109, 119, .16);
}

/*------------------------------------------------------------------
[3. Navigation / #navbar] - see /components/navbar/navbar-dropdown.css
*/
.default-light-menu {
    border: none !important;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset !important;
    color: #fff;
}

.default-light-menu:hover {
    background: #2196F3 !important;
    color: #fff;
}

/*------------------------------------------------------------------
[4. Content / #content]
*/
#body>.content {
    position: relative;
    padding: .5rem;
}

#body .content .page-title h3 {
    margin: 1rem 0;
}

.page-pretitle {
    font-size: .8rem;
    text-transform: uppercase;
    line-height: 1.6;
    color: #656d77;
}

.page-title {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5555556;
}

.detail-subtitle {
    font-size: .8rem;
    text-transform: uppercase;
    line-height: 1.6;
}

/*------------------------------------------------------------------
[5. Sidebar / #sidebar] - see /components/sidebar/sidebar-default.css
*/

/*------------------------------------------------------------------
[6. Boxes / .box] 
*/
.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.box-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
}

.box-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #f4f4f4;
    padding: 10px 20px;
    background-color: #FAFAFA;
    text-align: right;
}

.box-primary {
    border-top-color: #22a1f9;
}

/*------------------------------------------------------------------
[7. Dashboard Cards / .card] 
*/
.card {
    margin-bottom: 15px;
    box-shadow: rgba(35, 46, 60, .04) 0 2px 4px 0;
}

.card .content {
    padding: 15px 15px 10px 15px;
}

.card .content .icon-big {
    font-size: 3em;
    min-height: 64px;
    line-height: 64px;
}

.card .content .number {
    font-size: 1.5em;
    text-align: right;
    font-weight: bolder;
}

.card .content .footer {
    background-attachment: fixed;
    position: relative;
    padding: 0;
    line-height: 30px;
}

.card .content .stats {
    display: inline-block;
    color: #a9a9a9;
}

/*------------------------------------------------------------------
[8. Miscellaneous ] 
*/
.line {
    border-bottom: 1px solid #E0E0E0;
}

.nav-pills {
    padding: 15px;
    background-color: #E0E0E0;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}

.btn-rounded {
    border-radius: 10em;
    padding: 6px 8px;
    font-size: small;
    text-transform: none;
    text-shadow: none !important;
    background: #eaeaea;
    border-color: transparent;
    border: none;
}

.btn-rounded:hover {
    border-color: transparent;
    border: none;
}

#myTab {
    margin-bottom: 15px;
}

.no-margin {
    margin: 0;
}

.dfd {
    width: 100%;
}

.bg-lighter-grey {
    background: #FAFAFA;
}

/*------------------------------------------------------------------
[9. Adjustments to default behaviors] 
*/
a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.btn.btn-square {
    border-radius: 0;
}

.table td,
.table th {
    vertical-align: middle;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    font-size: .8rem;
    bottom: .9rem;
}

.dataTables_info {
    visibility: hidden;
}

table.dataTable>tbody>tr.child ul.dtr-details {
    display: block;
}

.admin-navbar .nav-tabs {
    border-bottom: 2px solid #dee2e6;
}

.admin-navbar .nav-tabs .nav-item {
    margin-bottom: -2px;
}

.admin-navbar .nav-tabs .nav-link {
    border: none;
    -webkit-transition: color .1s ease;
    transition: color .1s ease;
    color: inherit;
}

.admin-navbar .nav-tabs .nav-item.show .nav-link,
.admin-navbar .nav-tabs .nav-link.active {
    color: #007bff;
    background-color: #fff;
    border-bottom: 2px solid #22a1f9;

}

.tab-content {
    padding: 15px;
}

.svg-inline--fa {
    min-width: 15px;
}

.display-absolute {
    position: absolute;
}

.large-icon {
    font-size: 3em;
}

.license span {
    margin-bottom: 1em;
}

/*------------------------------------------------------------------
[10. Colors / .teal, .olive, .violet, .orange, .darkgray, .blue, .grey] 
*/
.teal {
    color: #00b5ad !important;
}

.olive {
    color: #b5cc18 !important;
}

.violet {
    color: #6435c9 !important;
}

.orange {
    color: #f2711c !important;
}

.darkgray {
    color: darkgray !important;
}

.blue {
    color: #2185d0 !important;
}

.grey {
    color: #767676 !important;
}

/*------------------------------------------------------------------
[11. Responsive properties] 
*/
@media (max-width: 768px) {
    .display-absolute {
        position: relative;
    }
}

@media (max-width: 680px) {
    #body.admin-navbar.active .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .admin-navbar .nav-dropdown .nav-link-menu {
        position: fixed !important;
        top: 52px !important;
        width: 100% !important;
        margin-top: 0;
    }

    .admin-navbar .nav-dropdown .nav-link {
        padding: 10px;
    }

    .admin-navbar .nav-dropdown .nav-link-menu::before {
        right: 50%;
    }

    #body .admin-navbar .navbar-collapse {
        display: none !important;
    }

    #body .admin-navbar .nav-dropdown .nav-item span {
        display: none !important;
    }

    .btn-header {
        display: none;
    }

    .admin-navbar .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}

@media (min-width: 200px) {
    .admin-navbar .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .admin-navbar .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
}