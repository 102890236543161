.form-control:focus,
.form-select:focus {
    border-color: #a29268;
    box-shadow: 0 0 0 0.20rem rgba(196, 175, 121, 0.26);
}

.btn-primary {
    background-color: #02365e;
    border-color: #02365e;
}

.btn-primary:hover {
    background-color: #002846;
    border-color: #002846;
}

.btn:disabled{
    background-color: #02365e;
    border-color: #02365e;
}

.btn-link{
    color: #02365e;
}

.btn-link:hover {
    color: #1b5e91;
}