.custom-navbar-top {
    background-color: #02365E !important;
    font-family: Arial, Times, serif;
}

.custom-navbar-top .nav-link {
    padding: 7px 15px;
    font-size: 13px;
    letter-spacing: 0.6px;
    font-weight: 600;
}

.custom-navbar-top .navbar-nav .nav-link:focus, 
.custom-navbar-top .navbar-nav .nav-link:hover{
    color: #BEAA76 !important;
}

.custom-navbar-top .navbar-nav .nav-link.active{
    color: rgba(255, 255, 255, 0.55);
}

.logoStyle{
    width: 55px;
    height: auto;
    margin: 0 auto;
    padding-left: 5px;
}