#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #323232;
    color: #fff;
    transition: all 0.3s;
    border-right: 1px solid #323232;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: .4rem 1rem;
    border-bottom: 1px solid rgba(101, 109, 119, .16);
    max-height: calc(55px + 2rem);
}

#sidebar ul.components {
    padding: 0 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: .8rem 1.5rem;
    font-size: 1rem;
    display: block;
    color: #f4f6fa;
}

#sidebar ul li a .fas {
    min-width: 20px;
    margin-right: 5px;
    text-align: center;
}

#sidebar ul li a:hover,
#sidebar ul li a.active {
    color: #fff;
    background: #242424;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #BEAA76;
}

#sidebar ul ul a {
    font-size: 1rem;
    background: #EEEEEE;
}

#sidebar a[data-toggle="collapse"] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: none;
}

#sidebar .sidebar-header img{
    max-height: 55px;
}

@media (max-width: 768px) {
    #sidebarCollapse span {
        display: none;
    }
}