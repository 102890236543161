:root{
    --color-primary: #02365E;
    --color-secondary: #beaa75;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root, .App, .Content{
    height: inherit;;
}

.site-body{
    display: flex;
    flex-direction: column;
    height: inherit;
}

.site-content{
    flex: 1;
}

body{
    font-family: 'Open Sans', Times, serif;
}

.overlay-text-image-container {
    font-family: 'Trajan Pro Regular';
    text-align: center;
    color: white;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #02365E;
    padding: 34px 0;
    background-image: url( '../../resources/img/bg-justica-min.webp' ) !important;
    background-position: bottom center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    display: flex;
    height: 500px;
}

@media only screen and (max-width: 768px) {
    .overlay-text-image-container {
        height: 700px;
    }
}

.overlay-text-image-container img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 0.4;
}

.overlay-text-image-container .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.overlay-text-image-container .overlay-text{
    font-family: 'Trajan Pro Regular';
    text-align: center;
    position: relative;
    color: #fff;
    font-size: 1.75em;
    font-weight: 200;
    max-width: 100%;
    z-index: 2;
}

.overlay-text-image-container .bottom-centered {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.overlay-text-image-container .arrasta-cima {
    font-size: 10px;
    font-weight: 200;
}

.overlay-text-image-container .arrasta-cima .fa {
    font-size: 1.5rem;
    padding: 5px;
}

.site-section {
    font-family: 'Trajan Pro Regular';
    position: relative;
    background-color: #fff;
    color: #323232;
    padding-top: 80px;
    padding-bottom: 80px;
}

.site-section.dark{
    background-color: #02365E;
    color: white;
}

.site-section.full-height{
    height: 100%;;
}

.site-section .container{
    height: 100%;
}

.site-section.fill-height-rows .row {
    height: inherit;
}

@media (max-width: 768px) {
    .site-section .card-image-col{
        display: none;
    }

    .site-section .site-section-text,
    .site-section .site-section-title{
        text-align: center;
    }

    .title-stroked{
        width: 100%;
        font-size: 12vw !important;
    }
}
.site-section .site-section-text{
    font-family: 'Open Sans', Times, serif;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
}

.site-section ul li .site-section-text{
    font-size: 1rem;
}

.site-section .site-section-title{
    font-weight: 600;
    font-size: 1.75rem;
}

.site-section.dark .site-section-title{
    color: #BEAA76;
}

.site-section .site-section-title-wrapper {
    position: relative;
    text-align: center;
}

.site-section .site-section-title-wrapper .site-section-title.stroked-shadow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    text-align: center;
    z-index: 2;
}

.text-align-center {
    text-align: center;
}

.saiba-mais{
    text-align: center;
    padding: 24px;
}

.equipe{
    text-align: center;
    padding: 20px;
    background-color: #FFFFFF;
    color: var(--color-primary);
    border-radius: 20px;
}

.badge-saiba-mais{
    border: 2px solid #BEAA76;
    border-radius: 50%;
    padding: 7px;
    min-width: 32px;
    text-align: center;
}

.text-saiba-mais {
    margin-left: -3px;
    background: white;
}

.site-section.dark .text-saiba-mais {
    background: #02365E;
}

.custom-footer {
    font-family: 'Open Sans', Times, serif;
    font-size: 13px;
    color: #323232;
    font-weight: bold;
}

.custom-footer.dark{
    color: #ffffff;
    background-color: #02365E;
}

.custom-footer .container{
    padding-top: 30px;
    padding-bottom: 30px;
}

.custom-footer .footer-section{
    padding-bottom: 15px;
    font-size: 15px;
}

.custom-footer .social-networks{
    display: flex;
    align-items: center;
}

.custom-footer .social-networks a{
    font-size: 16px;
    color: #BEAA76;
    margin-right: 15px;
    text-decoration: none;
    cursor: pointer;
}

.custom-footer .social-networks a:hover{
    color: #a89157;
}

.tipo-card{
    padding: 7px;
    min-width: 260px;
    height: 100%;
    border-radius: 4px;
    background: #02365E;
    color: #BEAA76;
}

.tipo-card .tipo-icon{
    font-size: 4em;
    color: #BEAA76;
}

.tipo-card .card-text{
    padding-top: 20px;
}

.tipo-card:hover {
    cursor: pointer;
    background: #0c436d;
}

.tipo-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

.page-header {
    position: relative;
    background-color: #f5f5f5;
    padding: 34px 0;
    background-image: url( '../../resources/img/bg-justica-min.webp' ) !important;
    background-position: bottom center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    display: flex;
    height: 200px;
}

.page-header.tipo-processo{
    background-image: url( '../../resources/img/pexels-anete-lusina-4792285.webp') !important;
}

.page-header.consulta-processos{
    background-image: url( '../../resources/img/37592538442_450f69a4e8_o.webp') !important;
}

.page-header.envio-documentos{
    background-image: url( '../../resources/img/pexels-mikhail-nilov-6893325.webp' ) !important;
}

.background-image-page-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    background-color: #000;
    height: 400px;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-image-page-header-overlay {
    background-color: #151515;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    -moz-opacity: .5;
    -webkit-opacity: .5;
    opacity: .5;
}

.page-header .page-header-title {
    font-family: 'Trajan Pro Regular';
    text-align: center;
    position: relative;
    color: #fff;
    font-size: 1.75em;
    font-weight: 400;
    max-width: 100%;
    z-index: 2;
}

.card-processo{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px !important;
    border: 1px solid #02365e21;
    border-radius: 0;
}

.card-processo .card-title {
    text-align: center;
    font-size: 24px;
    word-wrap: break-word;
    border-bottom: 1px solid #02365E59;
    color: #02365E;
    text-transform: uppercase;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.card-processo .card-processo-label{
    color: #02365E;
}

.card-processo .saiba-mais{
    padding: 12px 24px;
    width: auto;
    font-size: 15px;
    text-align: center;
    fill: #fff;
    border-radius: 3px;
    line-height: 1;
    font-weight: bold;
    margin: 20px 0 0 0;
    background-color: #02365e;
    border-color: #02365e;
}

.card-processo .saiba-mais:hover {
    background-color: #002846;
}

.custom-accordion .accordion-item {
    border: 1px solid #BEAA76;
    border-radius: 0;
    margin-bottom: 10px;
    background-color: white;
    margin-top: 10px;
}

.accordion-button:not(.collapsed) {
    background-color: inherit;
}

.custom-accordion .accordion-body a {
    color: #002846;
    text-decoration: none;
    font-weight: bold;
    width: 100%;
    display: block;
    margin-bottom: 10px;
}

.custom-accordion .accordion-body a:hover{
    color: #39719c;
} 

.custom-accordion .accordion-button:focus {
    box-shadow: none;
    border-bottom: 1px solid #beaa76;
    border-radius: 0;
}

.custom-accordion .accordion-button {
    font-weight: bold;
    color: #4a4a4a;
}

.form-envio {
    max-width: 800px;
}

.form-envio .form-group{
    clear: both;
    position: relative;
    margin-bottom: 25px;
}

.form-envio .form-label{
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-weight: 700;
    font-size: 18px;
    border: 0;
    outline: 0;
    color: #4a4a4a;
    word-wrap: break-word;
    line-height: 20px;
    vertical-align: baseline;
    box-sizing: border-box;
}

.fields-required {
    font-size: 13px;
    color: #4a4a4a;
    word-wrap: break-word;
}

.form-envio .req-symbol{
    color: #e80000;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
}

.container-processos,
.container-envio,
.container-processo{
    margin-top: 50px;
    margin-bottom: 100px;
}

.overlay-container {
    position: relative;
}
  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.title-stroked{
    display: block;
    position: relative;
    z-index: 1;
    font-size: 100px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #beaa75;
}

.site-section.dark .title-stroked{
    -webkit-text-stroke-color: #4a4a4a;
}

.pattern-right{
    background-image: url("../img/wireframe-pattern-r.webp");
    background-size: 21%;
    background-position: right;
    background-repeat: repeat-y;
}

.pattern-left{
    background-image: url("../img/wireframe-pattern-l.webp");
    background-size: 21%;
    background-repeat: repeat-y;
}

.home-card-container {
    text-align: left;
    overflow-x: hidden;
    width: 100%;
    display: block;
    position: relative;
    overflow-y: hidden;
    white-space: break-spaces;
}

.home-card{
    display: inline-block;
    width: calc(50% - 25px - 30px);
    word-wrap: break-word;
    padding: 30px 40px;
    margin: 25px;
    background-color: #0c436d;
}

.card-processo-img{
    max-width: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
}

.card-processo-img-container{
    max-width: 250px;
    display: inline;
    position: relative;
}

.col-card-processo{
    width: 74.233%;
}

@media (max-width: 768px) {
    .home-card{
        width: calc(100% - 25px - 30px);
    }

    .card-processo-img{
        max-width: unset;
        max-height: 150px;
    }

    .card-processo-img-container{
        max-width: unset;
        height: 150px;
        flex: 1 1 100%;
    }

    .col-card-processo{
        width: 100%
    }
}

.home-card-icon {
    font-size: 5rem;
    display: block;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 7px;
    border-bottom: 2px solid white;
}

.home-card-text{
    font-size: 1rem;
}

.documento-pendente td {
    background-color: #e2eff9;
    font-weight: bold;
}

.documento-pendente .btn-visualizar {
    color: #880808;
}

.documentos-processo{
    border: none;
    color: #000000;
}

.documentos-processo:hover{
    color: #000000;
    background-color: #f7f7f7;
    border: none;
}

.documentos-processo.active{
    border-bottom: 2px solid #14355B!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
}

.documentos-processo.active:hover{
    background-color: #f0f8ff;
}

.card-header-arquivos{
    background-color: #FFFFFF;
    padding-top: 0;
    padding-left: 9px;
    padding-right: 9px;
}

.linha-par {
    background-color: #f2f2f2;
    padding: 10px;
}

.linha-impar {
    background-color: #ffffff;
    padding: 10px;
}

.ultima-linha {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.arquivo-processo:hover{
    color: #0000FF;
}

.class-body{
    padding: 0 !important;
}

.tipo-card-atuacao{
    padding: 7px;
    min-width: 260px;
    height: 100%;
    border-radius: 20px;
    background: #02365E;
    color: #BEAA76;
}


.tipo-card-atuacao .tipo-icon{
    font-size: 4em;
    color: #BEAA76;
}

.tipo-card-atuacao .card-text{
    padding-top: 20px;
}

.tipo-card-atuacao:hover {
    cursor: pointer;
    background: #0c436d;
}

.text-card-atuacao{
    text-align: left;
    font-size: 18px;
    font-family: 'Open Sans', Times, serif;
    color: #FFFFFF;
}

.modal-header-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #02365E;
    color: #FFFFFF;
    border: none;
}

.modal-title-custom {
    flex-grow: 1;
    text-align: center;
    margin: 0;
}

.whatsapp-button {
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 1000;
}

.msgAlertaProcesso{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    overflow: hidden;
    resize: none;
}

.container-equipe {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-equipe {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    text-align: center;
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.card-equipe-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.card-equipe-content {
    margin-top: 15px;
    padding: 10px;
}

.card-equipe-content h1 {
    font-size: 18px;
    margin: 0;
}

@media (max-width: 575px) {
    .card-equipe-img {
        max-width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .card-equipe-img {
        max-width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .card-equipe-img {
        max-width: 255px;
    }
}

@media (min-width: 992px) {
    .card-equipe-img {
        max-width: 255px;
    }
}

.img-responsive {
    width: 100%;
}

@media (min-width: 768px) {
    .img-responsive {
        width: 60%;
    }
}

.descricao-advogado {
    white-space: pre-line;
}

.artigos {
    max-width: 500px;
    margin: 0 auto;
}

.card-post {
    position: relative;
    padding: 0 15px;
}

.card-post-figure {
    margin: 0;
}

.card-post-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
}

.card-post-content {
    padding: 10px;
    text-align: center;
}

.card-post-content h1 {
    font-size: 1.5rem;
    color: #333;
}
