.admin-content .container{
    max-width: unset !important;
}

.admin-navbar .admin-navbar-toggle{
    color: #323232;
}

.admin-navbar  .admin-navbar-toggle:hover {
    border: none;
}

.admin-card .card-header{
    font-weight: 600;
    color: #02365e;
    border-bottom-color: #beaa76;
    padding: 0.5rem 1rem;
    padding-right: 0.5rem;
}

.admin-card .card-footer {
    border-top-color: #beaa76;
}

.processo-file {
    font-weight: bold;
    padding: 5px;
    border-bottom: 1px solid #ddd;
}

.processo-file:hover {
    background-color: #eee;
}

.processo-new-file {
    color: #883131;
}

.processo-old-file {
    color: #02365e;
}

.processo-file-delete {
    float: right;
    padding: 0 5px;
}

.login-page{
    background-color: #02365E;
    margin: 0;
    max-width: unset;
    min-height: 100vh;
}

.login-container{
    background-color: #0c436d;
    max-width: 400px;
    padding: 15px;
    color: #fff;
}

.login-container .login-footer{
    margin-top: 30px;
}

.login-container .login-footer .btn{
    border-radius: 0;
}

.login-container .login-group {
    margin-top: 15px;
}

.login-group .input-group{
    border-radius: 0;
    border: none;
    border-radius: 0;
    border-bottom: 3px solid #c7b27b;
}

.login-group .input-group input{
    border-radius: inherit;
    border: none;
}

.login-group .input-group .input-group-text{
    border-radius:  inherit;
    border: none;
    color: #02365e;
}

.login-logo-container{
    text-align: center;
}

.login-logo-container img{
    padding: 15px;
    height: 115px;
}

.login-container .form-label{
    font-size: 12px;
}

.form-admin .form-admin-group {
    margin-bottom: 5px;
}

.form-admin .form-label {
    font-weight: bold;
    font-size: 14px;
    color: #444;
}

.form-admin .form-check {
    font-size: 14px;
    font-weight: bold;
    color: #444;
}

.form-admin .form-control,
.form-admin .form-select {
    border-radius: 0;
}

.custom-accordion-admin .accordion-item {
    border: 1px solid #BEAA76;
    border-radius: 0;
    margin-bottom: 10px;
    background-color: white;
    margin-top: 10px;
}

.custom-accordion-admin .accordion-button:not(.collapsed) {
    background-color: inherit;
}

.custom-accordion-admin .accordion-button:focus {
    box-shadow: none;
    border-bottom: 1px solid #beaa76;
    border-radius: 0;
}

.custom-accordion-admin .accordion-button {
    font-weight: bold;
    color: #4a4a4a;
}

.custom-accordion-admin .accordion-item,
.custom-accordion-admin .accordion-button:focus {
    border: 1px solid #DEE2E6;
    border-radius: 0;
}

.custom-accordion-admin .accordion-button {
    background-color: #f4f6fa;
}

#sidebar{
    background-color: #02365E;
}

#sidebar ul li a:hover, 
#sidebar ul li a.active{
    background-color: #01243f;
}

#sidebar .admin-sidebar-footer ul li a {
    color: #b9b9b9;
}

.admin-sidebar-footer {
    border-top: 1px solid rgba(101, 109, 119, .16);
}

.admin-toast {
    -webkit-box-shadow: -5px 5px 5px 0px rgba(50,50,50,0.47);
    -moz-box-shadow: -5px 5px 5px 0px rgba(50,50,50,0.47);
    box-shadow: -5px 5px 5px 0px rgba(50,50,50,0.47);
    border: none;
}

.admin-toast .toast-header {
    background: #cab57e;
    color: #444;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.admin-toast .toast-body {
    background: #02365E;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.admin-toast .toast-body .btn.btn-link {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}

.empresa-logo-container{
    display: block;
    width: 100%;
    text-align: center;
    background-color: #eee;
    position: relative;
}

.empresa-logo-container img{
    max-height: 125px;
    margin: 10px;
}

.empresa-logo-delete-button {
    position: absolute;
    top: 0;
    right: 0;
    color: #96272d;
}