.fade-alert {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-alert.fade-in {
    opacity: 1;
}

.fade-alert.fade-out {
    opacity: 0;
}
