@font-face {
    font-family: 'Trajan Pro';
    src: url('../fonts/trajan_pro-webfont.woff2') format('woff2'),
         url('../fonts/trajan_pro-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Trajan Pro Bold';
    src: url('../fonts/trajanpro-bold-webfont.woff2') format('woff2'),
         url('../fonts/trajanpro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Trajan Pro Regular';
    src: url('../fonts/trajanpro-regular-webfont.woff2') format('woff2'),
         url('../fonts/trajanpro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}